import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase";

import Home from "@/components/home";
import HomeEdit from "@/components/home/edit";
import EditOptions from "@/components/gallery/editOptions";
import Gallery from "@/components/gallery";
import EditGallery from "@/components/gallery/edit";
import DeleteGallery from "@/components/gallery/delete";
import Picture from "@/components/picture";
import EditPicture from "@/components/picture/edit";
import NewPicture from "@/components/picture/new";
import Contact from "@/components/contact";
import EditContact from "@/components/contact/edit";
import Dashboard from "@/components/dashboard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/home/edit",
    name: "HomeEdit",
    component: HomeEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/gallery/edit-options",
    name: "EditOptions",
    component: EditOptions,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/gallery/:slug",
    name: "Gallery",
    component: Gallery
  },
  {
    path: "/gallery/edit/:slug",
    name: "EditGallery",
    component: EditGallery,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/gallery/delete/:slug",
    name: "DeleteGallery",
    component: DeleteGallery,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/picture/new-picture",
    name: "NewPicture",
    component: NewPicture,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/picture/edit/:slug",
    name: "EditPicture",
    component: EditPicture,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/picture/:slug",
    name: "Picture",
    component: Picture
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/contact/edit",
    name: "EditContact",
    component: EditContact,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },

  { path: "/redirect-me", redirect: { name: "home" } },
  { path: "*", redirect: "/" }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to,from,next)=>{
  if (to.matched.some(r=>r.meta.requiresAuth)){
    let user=firebase.auth().currentUser
    console.log(user)
    if (user){
      next()
    } else {
      next({name:'Home'})
    } }else {
      next()
    }
  
})

export default router;
