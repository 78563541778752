import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  // apiKey: "AIzaSyA7uknnEgwuIg_9gSr76a4BKwj1WVXyjeA",
  // authDomain: "twogs-cf29d.firebaseapp.com",
  // databaseURL: "https://twogs-cf29d.firebaseio.com",
  // projectId: "twogs-cf29d",
  // storageBucket: "twogs-cf29d.appspot.com",
  // messagingSenderId: "842098831050",
  // appId: "1:842098831050:web:7967e3dfabf55cf28cce33"
  apiKey: "AIzaSyDC2PCyG9cXO2slu3d_JA2lByRUSUnjArg",
  authDomain: "sheilart.firebaseapp.com",
  databaseURL: "https://sheilart.firebaseio.com",
  projectId: "sheilart",
  storageBucket: "sheilart.appspot.com",
  messagingSenderId: "530882440964",
  appId: "1:530882440964:web:0988e8aff02eb45f58ea51",
  measurementId: "G-2LQXFJKWDZ"
};
// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const fb = firebaseApp.firestore();
const fs = firebaseApp.storage();
export { fb, fs };
