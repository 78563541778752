<template>
  <nav class="container nav navbar navbar-expand-sm navbar-dark bg-dark">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarToggler"
        aria-controls="navbarToggler"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarToggler">
        <span class="navbar-brand">
          <router-link class="nav-link" to="/home">
            <span class="fw-light">Sheil</span>
            <span class="">Art</span>
          </router-link>
        </span>
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a
              href="#"
              class="nav-link dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Galleries
            </a>
            <ul class="dropdown-menu bg-dark">
              <li
                class="dropdown-item bg-dark"
                v-for="(gal, index) in galleryList"
                :key="index"
              >
                <router-link class="nav-link" :to="'/gallery/' + gal.slug">
                  {{ gal.title }}
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/contact">
              Contact
            </router-link>
          </li>

          <li class="nav-item" v-if="avatar">
            <router-link class="nav-link" to="/dashboard">
              Dashboard
            </router-link>
          </li>
          <li class="nav-item nav-link" v-if="avatar" @click="logout">
            <img class="av" :src="avatar" />
          </li>
          <li v-else class="nav-item nav-link" @click="login">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,6A2,2 0 0,0 10,8A2,2 0 0,0 12,10A2,2 0 0,0 14,8A2,2 0 0,0 12,6M12,13C14.67,13 20,14.33 20,17V20H4V17C4,14.33 9.33,13 12,13M12,14.9C9.03,14.9 5.9,16.36 5.9,17V18.1H18.1V17C18.1,16.36 14.97,14.9 12,14.9Z"
              />
            </svg>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import firebase from "firebase";
import { mapGetters } from "vuex";

export default {
  name: "Nav",
  data() {
    return {
      avatar: null,
      drawer: null
    };
  },
  computed: {
    ...mapGetters(["galleryList"])
  },
  methods: {
    login() {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope("profile");
      provider.addScope("email");
      firebase.auth().signInWithPopup(provider);
    },
    logout() {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope("profile");
      provider.addScope("email");
      firebase.auth().signOut();
    }
  },

  created() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        const firestoreRef = firebase.firestore();
        firestoreRef
          .collection("users")
          .where("uid", "==", user.uid)
          .get()
          .then(snapshot => {
            if (!snapshot.empty) {
              this.avatar = user.photoURL;
            } else {
              firestoreRef
                .collection("unknown-users")
                .doc(user.uid)
                .set({ email: user.email })
                .then(() => {
                  this.logout();
                });
            }
          });
      } else {
        this.avatar = null;
      }
    });
  }
};
</script>

<style>
.av {
  max-height: 24px;
  max-width: 24px;
  border-radius: 12px;
}
</style>
