<template>
  <div class="container" v-if="galleryBySlug(gallerySlug)">
    <div class="row p-4 bg-dark">
      <h1 class="col-12">{{ galleryBySlug(gallerySlug).title }}</h1>
      <p class="col">
        {{ galleryBySlug(gallerySlug).description }}
      </p>
    </div>
    <div class="row g-0">
      <div
        class="card bg-dark col-lg-3 col-md-6 col-sm-12"
        v-for="(pic, index) in pictureByGallerySlug(gallerySlug)"
        :key="index"
      >
        <div class="card-body">
          <a :href="'/picture/' + pic.slug">
            <img :src="pic.thumb" class="card-img-top" />
          </a>
          <h4 class="card-title">{{ pic.title }}</h4>
          <div class="card-text">
            <p v-if="getGOptions.descriptions">{{ pic.description }}</p>
            <p v-if="getGOptions.materials">{{ pic.material }}</p>
            <p v-if="getGOptions.sizes">
              {{ pic.width }}&times;{{ pic.height }} inches
              <span v-if="dimIsNum(pic.width, pic.height)">
                | {{ pic.width * 2.5 }}&times;{{ pic.height * 2.5 }} cm</span
              >
            </p>
            <p v-if="getGOptions.prices">£{{ pic.price }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Gallery",
  data() {
    return {
      gallerySlug: this.$route.params.slug
    };
  },
  computed: {
    ...mapGetters(["galleryBySlug", "pictureByGallerySlug", "getGOptions"])
  },
  methods: {
    updateGallery() {
      this.gallerySlug = this.$route.params.slug;
    },
    dimIsNum(width, height) {
      return !isNaN(width) && !isNaN(height);
    }
  },
  watch: {
    $route: "updateGallery"
  }
};
</script>

<style>
.description {
  white-space: pre-line;
}
</style>
