<template>
  <div class="container" v-if="galleryBySlug(gallerySlug)">
    <p>
      You are about to delete the gallery &ldquo;{{
        galleryBySlug(gallerySlug).title
      }}&rdquo;
    </p>
    <div class="row g-0" v-if="pictureByGallerySlug(gallerySlug)[0]">
      <p>
        These pictures will remain on the site but they will not be moved to
        another gallery.
      </p>
      <div
        class="col-2"
        v-for="(pic, index) in pictureByGallerySlug(gallerySlug)"
        :key="index"
      >
        <img :src="pic.thumb" />
      </div>
    </div>
    <div class="row g-0" v-else>
      <p>
        There are no pictures in this gallery
      </p>
    </div>
    <div class="row justify-content-around m-5">
      <button
        class="col-2 btn btn-danger"
        @click.prevent="deleteGallery(galleryBySlug(gallerySlug))"
      >
        <span class="material-icons">delete</span>Delete
      </button>
      <button class="col-2 btn btn-warning" @click="goBack">
        <span class="material-icons">cancel</span>Cancel
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "DeleteGallery",
  data() {
    return {
      gallerySlug: this.$route.params.slug
    };
  },
  computed: {
    ...mapGetters(["galleryBySlug", "pictureByGallerySlug"])
  },
  methods: {
    ...mapActions({ actionDeleteGallery: "deleteGallery" }),
    goBack() {
      this.$router.go(-1);
    },
    deleteGallery(gallery) {
      this.actionDeleteGallery(gallery);
      this.goBack();
    },
    routeUpdated() {
      this.gallerySlug = this.$route.params.slug;
    }
  },
  watch: {
    $route: "routeUpdated"
  }
};
</script>

<style>
.description {
  white-space: pre-line;
}
</style>
