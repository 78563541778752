<template>
  <div class="card bg-dark" v-if="picture">
    <div class="card-body">
      <img class="card-img-top" :src="picture.full" />
      <h3 class="card-title">
        {{ picture.title }}
      </h3>
      <div class="card-text text-white ">
        <button class="btn btn-small btn-primary" @click="goBack">
          <span class="material-icons">arrow_back</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Picture",
  computed: {
    ...mapGetters(["pictureList"]),
    picture() {
      return this.pictureList.filter(p => {
        return p.slug == this.$route.params.slug;
      })[0];
    }
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style></style>
