import Vue from "vue";
// import VueRouter from "vue-router";
import App from "./App.vue";
import { store } from "./store/store";
import router from "./router";
import { fb } from "@/firebase/init";
import firebase from "firebase";

new Vue({
  el: "#app",
  store: store,
  router,
  created() {
    // Set up firebase authentication
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    fb.collection("hero")
      .get()
      .then(snapshot => {
        let hero = { ...snapshot.docs[0].data() };
        hero.id = snapshot.docs[0].id;
        this.$store.commit("updateHero", hero);
      });
    fb.collection("contact")
      .get()
      .then(snapshot => {
        let contact = { ...snapshot.docs[0].data() };
        contact.id = snapshot.docs[0].id;
        this.$store.commit("updateContact", contact);
      });
    fb.collection("goptions")
      .get()
      .then(snapshot => {
        let gOptions = { ...snapshot.docs[0].data() };
        gOptions.id = snapshot.docs[0].id;
        this.$store.commit("updateGOptions", gOptions);
      });
    fb.collection("galleries")
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          let gallery = doc.data();
          gallery.id = doc.id;
          this.$store.commit("updateGallery", gallery);
        });
      });
    fb.collection("pictures")
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          let pic = doc.data();
          pic.id = doc.id;
          this.$store.commit("updatePicture", pic);

          if (pic.carousel) {
            this.$store.commit("updateCarousel", pic);
          }
        });
      });
  },

  render: h => h(App)
});
