<template>
  <div class="container">
    <div class="row">
      <label for="galleryTitle" class="form-label">Title</label>
      <input
        v-model.lazy="gallery.title"
        type="text"
        class="form-control"
        id="galleryTitle"
      />
      <div id="galleryTitleHelp" class="form-text">
        Name of this gallery
      </div>
    </div>

    <div class="row">
      <label for="frontPageImage" class="form-label">Image</label>
      <select v-model="gallery.image" :option="picList" class="form-select">
        <option
          v-for="(pic, index) in pictureByGallerySlug(gallery.slug)"
          :value="pic.slug"
          :key="index"
        >
          {{ pic.slug }}
        </option>
      </select>
      <div id="frontPageImageHelp" class="form-text">
        This gallery's image for the front page
      </div>
    </div>

    <div class="row">
      <label for="galleryDesc" class="form-label">Description</label>
      <textarea
        v-model.lazy="gallery.description"
        rows="10"
        class="form-control"
        id="galleryDesc"
      />
      <div id="galleryDescHelp" class="form-text">
        Description of this gallery
      </div>
    </div>
    <div class="row">
      <button class="col-2 btn btn-primary" @click="saveGallery(gallery)">
        <span class="material-icons">edit</span>Save
      </button>
      <div class="col-8"></div>
      <button class="col-2 btn btn-warning" @click="goBack">
        <span class="material-icons">cancel</span>Cancel
      </button>
    </div>
  </div>
</template>

<script>
import slugify from "slugify";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "EditGallery",
  data() {
    return {
      gallerySlug: this.$route.params.slug,
      valid: true,
      error: "",
      titleRules: [v => !!v || "Title is required"]
    };
  },
  computed: {
    ...mapGetters(["galleryBySlug", "galleryList", "pictureByGallerySlug"]),
    gallery() {
      let tGal = this.galleryList.filter(g => {
        return g.slug == this.gallerySlug;
      })[0];
      return { ...tGal };
    }
  },
  methods: {
    ...mapActions(["updateGallery", "createGallery"]),
    goBack() {
      this.$router.go(-1);
    },
    // picList() {
    //   return this.pictureList.map((p) => {
    //     return p.slug;
    //   });
    // },

    cancelUpdate() {
      this.$store.commit("deleteGallery", { id: 0 });
      this.goBack();
    },
    saveGallery(gallery) {
      this.error = "";
      gallery.slug = slugify(gallery.title, {
        replacement: "-",
        remove: /[/$*_+~.,()'"!\-:@]/g,
        lower: true
      });
      if (gallery.slug == "new-gallery") {
        this.error = "gallery can't be called " + '"' + gallery.title + '"';
      }
      if (
        this.gallerySlug != gallery.slug &&
        this.galleryList.filter(g => {
          return g.slug == gallery.slug;
        }).length === 1
      ) {
        this.error =
          "there's already a gallery called " + '"' + gallery.title + '"';
      }

      if (!this.error) {
        if (this.gallerySlug == "new-gallery") {
          this.$store.commit("deleteGallery", { id: 0 });
          this.createGallery(gallery);
        } else {
          this.updateGallery(gallery);
        }
        this.goBack();
      } else {
        alert(this.error);
      }
    },
    updateRoute() {
      this.gallerySlug = this.$route.params.slug;
    }
  },
  created() {
    if (this.$route.params.slug == "new-gallery") {
      this.$store.commit("createGallery", {
        title: "New Gallery",
        slug: "new-gallery",
        id: 0
      });
    }
  },
  watch: {
    $route: "updateRoute"
  }
};
</script>
