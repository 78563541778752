<template>
  <div class="container">
    <div class="row bg-dark">
      <router-link class="btn btn-primary col-2" to="home/edit">
        <span class="material-icons">edit</span>
        Edit Front Page
      </router-link>

      <router-link class="btn btn-primary col-2" to="contact/edit">
        <span class="material-icons">edit</span>
        Edit Contact Page
      </router-link>
    </div>
    <hr />

    <div class="bg-dark">
      <span class="fs-3"> Galleries </span>
      <router-link class="btn btn-primary" to="gallery/edit-options">
        <span class="material-icons">edit</span>
        Edit options
      </router-link>
      <div class="row">
        <div
          class="card col-2 bg-dark"
          v-for="(gallery, index) in galleryList"
          :key="index"
        >
          <div class="card-title">
            {{ gallery.title }}
          </div>
          <div class="card-text">
            <router-link
              :to="'gallery/edit/' + gallery.slug"
              class="btn btn-primary"
            >
              <span class="material-icons">edit</span>edit
            </router-link>
            <router-link
              :to="'gallery/delete/' + gallery.slug"
              class="btn btn-danger"
            >
              <span class="material-icons">delete</span>delete
            </router-link>
          </div>
        </div>
        <div class="row justify-content-center m-5">
          <div class="col-2">
            <button
              v-if="asyncBusy"
              class="btn btn-primary"
              type="button"
              disabled
            >
              <span class="spinner-border text-warning"> </span>

              Loading…
            </button>

            <router-link
              v-else
              to="gallery/edit/new-gallery/"
              class="btn btn-primary"
            >
              <span class="material-icons">add</span>
              New Gallery
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="bg-dark">
      <span class="fs-3"> Pictures </span>

      <div class="row justify-content-center m-5">
        <div class="col-2">
          <router-link class="btn btn-primary" to="picture/new-picture">
            <span class="material-icons">add</span>
            New Picture
          </router-link>
        </div>
      </div>

      <div class="row g-0" v-if="pictureList">
        <div
          class="card col-2 bg-dark"
          v-for="(pic, index) in pictureList"
          :key="index"
        >
          <img class="card-image-top" :src="pic.thumb" />
          <div class="card-text">
            <p>{{ pic.title }}</p>
            <router-link
              class="btn btn-primary btn-sm"
              :to="'picture/edit/' + pic.slug"
            >
              <span class="material-icons">edit</span>edit
            </router-link>
            <button class="btn btn-danger btn-sm" @click="deletePicture(pic)">
              <span class="material-icons">delete</span>delete
            </button>
          </div>

          <div class="card-footer"></div>
        </div>
        <div class="row justify-content-center m-5">
          <div class="col-2">
            <router-link
              class="btn btn-primary"
              to="picture/new-picture"
            >
              <span class="material-icons">add</span>
              New Picture
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "Dashboard",
  computed: {
    ...mapGetters(["galleryList", "pictureList", "asyncBusy"]),
  },
  methods: {
    ...mapActions(["deletePicture"]),
  },
};
</script>
<style></style>
