<template>
  <div v-if="getHero" class="flex-container">
    <div class="row">
      <div
        v-if="getHero.description"
        ref="safpimg"
        class="puff col-12 text-white"
      >
        <h1 class="display-3">
          {{ getHero.title }}
        </h1>
        <h2>
          {{ getHero.subtitle }}
        </h2>
        <p>
          {{ getHero.description }}
        </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <router-link
        class="col-12 col-md-4 col-lg-3 p-1 link-light"
        v-for="(gal, index) in galleryList"
        :key="index"
        :to="'/gallery/' + gal.slug"
      >
        <div
          v-if="pictureBySlug(gal.image)"
          class="text-center gallink fs-3"
          :style="setBG(gal.image)"
        >
          {{ gal.title }}
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Home",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["galleryList", "getHero", "pictureBySlug"])
  },
  mounted() {
    // this.$refs.safpimg.style.backgroundImage =
    //   "url('" + this.pictureBySlug(this.getHero.image).full + "')";
  },
  methods: {
    setBG(i) {
      return "backgroundImage:url(" + this.pictureBySlug(i).thumb + ")";
    }
  }
};
</script>

<style>
a {
  text-decoration: none;
}
.puff {
  white-space: pre-line;
}
.gallink {
  border-radius: 1rem;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  height: 10em;
}
</style>
