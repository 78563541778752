<template>
  <div class="container">
    <div class="row justify-content-center">
      <p class="col-8">{{ getContact.text }}</p>
    </div>
    <div class="row justify-content-center">
      <p class="col-1 text-muted">phone</p>
      <p class="col-3 lead">{{ getContact.phone }}</p>
      <p class="col-1 text-muted">email</p>
      <p class="col-3 lead">{{ getContact.email }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Contact",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getContact"])
  }
};
</script>

<style>
* {
  white-space: pre-line;
}
</style>
