<template>
  <div class="container">
    <!-- <div class="row">
      <div class="col-1">
        Header
      </div>
      <div class="col-9">
        <v-textarea outlined v-model="contact.text" label="Blurb"></v-textarea>

        <v-text-field
          outlined
          v-model="contact.phone"
          label="Phone"
        ></v-text-field>
        <v-text-field
          outlined
          v-model="contact.email"
          label="email"
        ></v-text-field>
      </v-col>
    </div> -->
    <div class="row">
      <label for="contactPhone" class="form-label">Telephone</label>
      <input
        type="number"
        v-model.lazy="contact.phone"
        class="col-1"
        id="contactPhone"
      />
      <div id="contactPhoneHelp" class="form-text">
        Telephone Number
      </div>
    </div>
    <div class="row">
      <label for="contactEmail" class="form-label">Telephone</label>
      <input
        type="email"
        v-model.lazy="contact.email"
        class="col-3"
        id="contactEmail"
      />
      <div id="contactEmailHelp" class="form-text">
        Telephone Number
      </div>
    </div>
    <div class="row">
      <button class="col-2 btn btn-primary" @click="updateContact(contact)">
        <span class="material-icons">edit</span>Save
      </button>
      <div class="col-8"></div>
      <button class="col-2 btn btn-warning" @click="goBack">
        <span class="material-icons">cancel</span>Cancel
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "EditContact",
  computed: {
    ...mapGetters(["getContact"]),
    contact() {
      return { ...this.getContact };
    }
  },
  methods: {
    ...mapActions({ actionUpdateContact: "updateContact" }),
    updateContact(contact) {
      this.actionUpdateContact(contact);
      this.goBack();
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>
