import Vue from "vue";
import Vuex from "vuex";
import { fb } from "@/firebase/init";
import { fs } from "@/firebase/init";
import date from "date-and-time"

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    user: {
      uid: "",
      email: "",
      name: "",
      avatar: "",
      admin: false
    },
    hero: {
      description: "",
      id: "",
      image: "",
      title: "",
      subtitle: ""
    },
    galleries: [],
    gOptions: {
      id: "",
      descriptions: false,
      materials: false,
      prices: false,
      sizes: false
    },
    contact: {},
    pictures: [],
    carousel: [],
    busy: false
  },

  getters: {
    galleryList: state => {
      return state.galleries;
    },
    galleryBySlug: state => slug => {
      return state.galleries.filter(gal => {
        return gal.slug == slug;
      })[0];
    },
    pictureList: state => {
      return state.pictures;
    },
    pictureBySlug: state => slug => {
      return state.pictures.filter(pic => {
        return pic.slug === slug;
      })[0];
    },
    pictureByGallerySlug: state => slug => {
      return state.pictures.filter(pic => {
        return pic.gallery == slug;
      });
    },
    getHero: state => {
      return state.hero;
    },
    getContact: state => {
      return state.contact;
    },
    getCarousel: state => {
      return state.carousel;
    },
    asyncBusy: state => {
      return state.busy;
    },
    getGOptions: state => {
      return state.gOptions;
    }
  },

  mutations: {
    createGallery: (state, payload) => {
      state.galleries.push(payload);
    },
    updateGallery: (state, payload) => {
      state.galleries = state.galleries.filter(p => {
        return p.id !== payload.id;
      });
      state.galleries.push(payload);
    },
    deleteGallery: (state, payload) => {
      state.galleries = state.galleries.filter(p => {
        return p.id !== payload.id;
      });
    },
    updateHero: (state, payload) => {
      state.hero = payload;
    },
    updateContact: (state, payload) => {
      state.contact = payload;
    },
    updateGOptions: (state, payload) => {
      state.gOptions = payload;
    },
    createPicture: (state, payload) => {
      state.pictures.push(payload);
    },
    updatePicture: (state, payload) => {
      // state.pictures[state.pictures.findIndex(p=>{return p.id == payload.id})]={...payload}
      state.pictures = state.pictures.filter(p => {
        return p.id !== payload.id;
      });
      state.pictures.push(payload);
    },
    updateCarousel: (state, payload) => {
      if (payload.carousel) {
        state.carousel.push(payload.full);
      } else {
        state.carousel = state.carousel.filter(p => {
          return p !== payload.full;
        });
      }
    },
    deletePicture: (state, payload) => {
      state.pictures = state.pictures.filter(p => {
        return p.id !== payload.id;
      });
    },
    updateBusy: (state, payload) => {
      state.busy = payload;
    },
    updateUser: (state, payload) => {
      state.user = payload;
    },
    deleteUser: (state, payload) => {
      state.user.uid = "";
      state.user.email = "";
      state.user.name = "";
      state.user.avatar = "";
      state.user.admin = payload;
    }
  },
  actions: {
    createGallery: (context, payload) => {
      context.commit("updateBusy", true);
      fb.collection("/galleries")
        .add(payload)
        .then(() => {
          context.commit("createGallery", payload);
          context.commit("updateBusy", false);
        })
        .catch(error => {
          let errTime = date.format(new Date(), 'YYYYMMDD HH:mm:ss')
          fb.collection("errors")
            .add({ "date": errTime, "error": error.message })
          context.commit("updateBusy", false);
        });
    },
    updateGallery: (context, payload) => {
      context.commit("updateBusy", true);
      fb.doc("/galleries/" + payload.id)
        .update(payload)
        .then(() => {
          context.commit("updateGallery", payload);
          context.commit("updateBusy", false);
        })
        .catch(error => {
          let errTime = date.format(new Date(), 'YYYYMMDD HH:mm:ss')
          fb.collection("errors")
            .add({ "date": errTime, "error": error.message })
          context.commit("updateBusy", false);
        });
    },

    createPicture: (context, payload) => {
      context.commit("updateBusy", true);
      const fileName = payload.slug + ".jpg";
      fs.ref()
        .child(fileName)
        .put(payload.file)
        .then(snapshot => {
          snapshot.ref.getDownloadURL().then(url => {
            console.log(">>>>>" + url);
            payload.full = url;
            payload.thumb = url;

            // remember the original slug in case we need to delete the file in future ...
            // if we ever rename a 'picture' here we lose track of the filename on firebase
            payload.file = payload.slug;
            fb.collection("pictures")
              .add(payload)
              .then(docRef => {
                payload.id = docRef.id;
                context.commit("createPicture", payload);
                context.commit("updateCarousel", payload);
              });
            context.commit("updateBusy", false);
          });
        })
        .catch(error => {
          let errTime = date.format(new Date(), 'YYYYMMDD HH:mm:ss')
          fb.collection("errors")
            .add({ "date": errTime, "error": error.message })
          context.commit("updateBusy", false);
        });
    },
    updatePicture: (context, payload) => {
      context.commit("updateBusy", true);
      fb.doc("/pictures/" + payload.id)
        .update(payload)
        .then(() => {
          context.commit("updatePicture", payload);
          context.commit("updateCarousel", payload);

          context.commit("updateBusy", false);
        })
        .catch(error => {
          let errTime = date.format(new Date(), 'YYYYMMDD HH:mm:ss')
          fb.collection("errors")
            .add({ "date": errTime, "error": error.message })
        });
    },
    deletePicture: (context, payload) => {
      context.commit("updateBusy", true);
      fs.ref()
        .child(payload.file + ".jpg")
        .delete()
        .catch((error) => {
          let errTime = date.format(new Date(), 'YYYYMMDD HH:mm:ss')
          fb.collection("errors")
            .add({ "date": errTime, "error": error.message })
          context.commit("updateBusy", false);
        });

      fs.ref()
        .child(payload.file + "_1280x1280.jpg")
        .delete()
        .catch(error => {
          let errTime = date.format(new Date(), 'YYYYMMDD HH:mm:ss')
          fb.collection("errors")
            .add({ "date": errTime, "error": error.message })
          context.commit("updateBusy", false);
        })
      fs.ref()
        .child(payload.file + "_256x256.jpg")
        .delete()
        .catch(error => {
          let errTime = date.format(new Date(), 'YYYYMMDD HH:mm:ss')
          fb.collection("errors")
            .add({ "date": errTime, "error": error.message })
          context.commit("updateBusy", false);
        });
      fb.doc("/pictures/" + payload.id)
        .delete()
        .then(() => {
          // remove from carousel
          payload.carousel = false;
          context.commit("updateCarousel", payload);
          context.commit("deletePicture", payload);
          context.commit("updateBusy", false);
        });
    },

    updateHero: (context, payload) => {
      context.commit("updateBusy", true);
      fb.doc("/hero/" + payload.id)
        .update(payload)
        .then(() => {
          context.commit("updateHero", payload);
          context.commit("updateBusy", false);
        })
        .catch(error => {
          let errTime = date.format(new Date(), 'YYYYMMDD HH:mm:ss')
          fb.collection("errors")
            .add({ "date": errTime, "error": error.message })
        });
    },

    updateContact: (context, payload) => {
      context.commit("updateBusy", true);
      fb.doc("/contact/" + payload.id)
        .update(payload)
        .then(() => {
          context.commit("updateContact", payload);
          context.commit("updateBusy", false);
        })
        .catch(error => {
          let errTime = date.format(new Date(), 'YYYYMMDD HH:mm:ss')
          fb.collection("errors")
            .add({ "date": errTime, "error": error.message })
        });
    },

    updateGOptions: (context, payload) => {
      context.commit("updateBusy", true);
      fb.doc("/goptions/" + payload.id)
        .update(payload)
        .then(() => {
          context.commit("updateGOptions", payload);
          context.commit("updateBusy", false);
        })
        .catch(error => {
          let errTime = date.format(new Date(), 'YYYYMMDD HH:mm:ss')
          fb.collection("errors")
            .add({ "date": errTime, "error": error.message })
        });
    },
    deleteGallery: (context, payload) => {
      context.commit("updateBusy", true);
      fb.doc("/galleries/" + payload.id)
        .delete()
        .then(() => {
          context.commit("deleteGallery", payload);
          context.commit("updateBusy", false);
        });
    }
  }
});
