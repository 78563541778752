<template>
  <div class="container">
    <form>
      <div class="col-12">
        <h1>Editing &ldquo;{{ picture.title }}&rdquo;</h1>
      </div>
      <div class="row justify-content-around">
        <div class="col-3">
          <label for="pictureTitle">Name</label>
          <input
            type="text"
            v-model.lazy.trim="picture.title"
            class="form-control"
            id="pictureTitle"
            required
          />
        </div>

        <div class="col-2">
          <label for="pictureGallery">Gallery</label>
          <select
            class="form-select"
            v-model.number="picture.gallery"
            id="pictureGallery"
            required
          >
            <option
              v-for="(gallery, index) in galleryList"
              :value="gallery"
              :key="index"
            >
              {{ gallery }}
            </option>
          </select>
        </div>
      </div>
      <div class="col">
        <label for="pictureDescription">Description</label>
        <textarea
          v-model="picture.description"
          rows="10"
          class="form-control"
          id="pictureDescription"
        />
      </div>
      <div class="row justify-content-around">
        <div class="col-2">
          <label for="picturePrice">Price</label>
          <input
            type="number"
            class="form-control"
            prefix="£"
            v-model="picture.price"
            id="picturePrice"
          />
        </div>
        <div class="col-2">
          <label for="pictureWidth">Width</label>
          <input
            class="form-control"
            v-model="picture.width"
            id="pictureWidth"
          />
        </div>
        <div class="col-2">
          <label for="pictureHeight">Height</label>
          <input
            class="form-control"
            v-model="picture.height"
            id="pictureHeight"
          />
        </div>
        <div class="col-2">
          <label for="pictureMaterial">Material</label>
          <input
            class="form-control"
            v-model="picture.material"
            id="pictureMaterial"
          />
        </div>
      </div>
      <!-- <div class="form-check">
        <input
          id="editPictureCarousel"
          :value="picture.carousel"
          type="checkbox"
          class="form-check-input"
        />
      </div> -->

      <div class="row justify-content-around m-5">
        <button
          class="col-2 btn btn-primary"
          @click.prevent="savePicture(picture)"
        >
          <span class="material-icons">edit</span>Save
        </button>
        <button class="col-2 btn btn-warning" @click="goBack">
          <span class="material-icons">cancel</span>Cancel
        </button>
      </div>
      <img class="col img-fluid" :src="picture.full" />
    </form>
  </div>
</template>

<script>
import slugify from "slugify";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "EditPicture",
  data() {
    return {
      picSlug: this.$route.params.slug,
      file: null,
      valid: true,
      error: ""
    };
  },
  computed: {
    ...mapGetters({
      getGalleryList: "galleryList",
      getPictureList: "pictureList"
    }),
    galleryList() {
      return this.getGalleryList.map(h => {
        return h.slug;
      });
    },
    picture() {
      let tPic = this.getPictureList.filter(pic => {
        return pic.slug == this.$route.params.slug;
      })[0];
      return { ...tPic };
    }
  },

  methods: {
    ...mapActions({ actionUpdatePicture: "updatePicture" }),
    goBack() {
      this.$router.go(-1);
    },
    savePicture(picture) {
      this.error = "";
      //update the slug, and validate form
      if (picture.title === "") {
        console.log("-" + picture.title + "-");
        this.error = "picture needs a name";
      }
      picture.slug = slugify(picture.title, {
        replacement: "-",
        remove: /[/$*_+~.,()'"!\-:@]/g,
        lower: true
      });
      if (picture.slug == "new-picture") {
        this.error = "picture can't be called " + '"' + picture.title + '"';
      }
      if (
        this.picSlug != picture.slug &&
        this.getPictureList.filter(pic => pic.slug == picture.slug).length === 1
      ) {
        this.error =
          "there's already a picture called " + '"' + picture.title + '"';
      }
      if (!this.error) {
        this.actionUpdatePicture(picture);
        this.goBack();
      } else {
        alert(this.error);
      }
    },
    updateRoute() {
      this.picSlug = this.$route.params.slug;
    }
  },
  watch: {
    $route: "updateRoute"
  }
};
</script>
