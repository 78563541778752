<template>
  <div class="container">
    <Nav></Nav>
    <router-view></router-view>
    <div class="row justify-content-end mt-5">
      <div class="col-auto">
        Sheila Smith <span class="material-icons">copyright</span> All Rights
        Reserved
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "@/components/Nav";
// import { mapActions } from "vuex";

export default {
  name: "App",
  components: {
    Nav
  },
  methods: {
    // ...mapActions(["createCarousel", "createPicture", "createGallery"])
  },
  data: () => ({})
};
</script>

<style>
#app {
  font-family: Roboto, Arial, Helvetica, sans-serif;
}
</style>
