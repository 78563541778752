<template>
  <div class="container">
    <div class="row">
      <label for="frontPageTitle" class="form-label">Title</label>
      <input
        type="text"
        v-model.lazy="hero.title"
        class="form-control"
        id="frontPageTitle"
      />
      <div id="frontPageTitleHelp" class="form-text">
        The main title for the front page
      </div>
    </div>
    <!-- <div class="row">
      <label for="frontPageImage" class="form-label">Image</label>
      <select v-model="hero.image" :option="picList" class="form-select">
        <option v-for="(pic, index) in picList()" :value="pic" :key="index">
          {{ pic }}
        </option>
      </select>
      <div id="frontPageImageHelp" class="form-text">
        The main image for the front page
      </div>
    </div> -->
    <div class="row">
      <label for="frontPageSubtitle" class="form-label">Subtitle</label>
      <input
        type="text"
        v-model.lazy="hero.subtitle"
        class="form-control"
        id="frontPageSubtitle"
      />
      <div id="frontPageSubtitleHelp" class="form-text">
        A short descriptive subtitle
      </div>
    </div>
    <div class="row">
      <label for="frontPageDesc" class="form-label">Description</label>
      <textarea
        v-model.lazy="hero.description"
        rows="10"
        class="form-control"
        id="frontPageDesc"
      />
      <div id="frontPageDescHelp" class="form-text">
        Long text for the front page
      </div>
    </div>

    <div class="row">
      <button class="col-2 btn btn-primary" @click="updateHero(hero)">
        <span class="material-icons">edit</span>Save
      </button>
      <div class="col-8"></div>
      <button class="col-2 btn btn-warning" @click="goBack">
        <span class="material-icons">cancel</span>Cancel
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "HomeEdit",
  computed: {
    ...mapGetters(["getHero", "pictureList"]),
    hero() {
      return { ...this.getHero };
    }
  },
  methods: {
    ...mapActions({ actionUpdateHero: "updateHero" }),
    updateHero(hero) {
      this.actionUpdateHero(hero);
      this.goBack();
    },
    picList() {
      return this.pictureList.map(p => {
        return p.slug;
      });
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>
