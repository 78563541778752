<template>
  <div class="container">
    <div class="row m-4">
      <h1>
        Gallery Options
      </h1>
      <div class="col-3">
        <input
          id="GODesc"
          class="form-check-input"
          type="checkbox"
          v-model="options.descriptions"
        />
        <label for="GODesc" class="form-check-label">Show descriptions</label>
      </div>
      <div class="col-3">
        <input
          id="GOSize"
          class="form-check-input"
          type="checkbox"
          v-model="options.sizes"
        />
        <label for="GOSize" class="form-check-label">Show sizes</label>
      </div>
      <div class="col-3">
        <input
          id="GOPrices"
          class="form-check-input"
          type="checkbox"
          v-model="options.prices"
        />
        <label for="GOPrices" class="form-check-label">Show prices</label>
      </div>
      <div class="col-3">
        <input
          id="GOMat"
          class="form-check-input"
          type="checkbox"
          v-model="options.materials"
        />
        <label for="GODesc" class="form-check-label">Show materials</label>
      </div>
      <v-col cols="2">
        <v-checkbox
          outlined
          label="Show sizes"
          v-model="options.sizes"
        ></v-checkbox>
      </v-col>
      <v-col cols="2">
        <v-checkbox
          outlined
          label="Show materials"
          v-model="options.materials"
        ></v-checkbox>
      </v-col>
      <v-col cols="2">
        <v-checkbox
          outlined
          label="Show prices"
          v-model="options.prices"
        ></v-checkbox>
      </v-col>
    </div>
    <div class="row">
      <button class="col-2 btn btn-primary" @click="updateGOptions(options)">
        <span class="material-icons">edit</span>Save
      </button>
      <div class="col-8"></div>
      <button class="col-2 btn btn-warning" @click="goBack">
        <span class="material-icons">cancel</span>Cancel
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "EditOptions",
  computed: {
    ...mapGetters(["getGOptions"]),
    options() {
      return { ...this.getGOptions };
    }
  },
  methods: {
    ...mapActions({ actionUpdateGOptions: "updateGOptions" }),
    updateGOptions(options) {
      this.actionUpdateGOptions(options);
      this.goBack();
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>
