<template>
  <div class="container">
    <form>
      <div class="col-12">
        <h1>Editing &ldquo;{{ picture.title }}&rdquo;</h1>
      </div>
      <div class="row justify-content-around">
        <div class="col-3">
          <label for="pictureTitle">Name</label>
          <input
            type="text"
            v-model.lazy.trim="picture.title"
            class="form-control"
            id="pictureTitle"
            required
          />
        </div>

        <div class="col-2">
          <label for="pictureGallery">Gallery</label>
          <select
            class="form-select"
            v-model.number="picture.gallery"
            id="pictureGallery"
            required
          >
            <option
              v-for="(gallery, index) in galleryList"
              :value="gallery"
              :key="index"
            >
              {{ gallery }}
            </option>
          </select>
        </div>
      </div>
      <div class="col">
        <label for="pictureDescription">Description</label>
        <textarea
          v-model="picture.description"
          rows="10"
          class="form-control"
          id="pictureDescription"
        />
      </div>
      <div class="row justify-content-around">
        <div class="col-2">
          <label for="picturePrice">Price</label>
          <input
            type="number"
            class="form-control"
            prefix="£"
            v-model="picture.price"
            id="picturePrice"
          />
        </div>
        <div class="col-2">
          <label for="pictureWidth">Width</label>
          <input
            class="form-control"
            v-model="picture.width"
            id="pictureWidth"
          />
        </div>
        <div class="col-2">
          <label for="pictureHeight">Height</label>
          <input
            class="form-control"
            v-model="picture.height"
            id="pictureHeight"
          />
        </div>
        <div class="col-2">
          <label for="pictureMaterial">Material</label>
          <input
            class="form-control"
            v-model="picture.material"
            id="pictureMaterial"
          />
        </div>
      </div>
      <div class="col m-3">
        <label for="newImage">
          <input
            class="btn btn-secondary"
            id="newImage"
            ref="files"
            type="file"
            @change="getFile"
            accept="image/*"
        /></label>
        <img class="col img-fluid" :src="picture.full" />
      </div>

      <div class="row justify-content-around m-5">
        <button
          class="col-2 btn btn-primary"
          @click.prevent="savePicture(picture)"
        >
          <span class="material-icons">edit</span>Save
        </button>
        <button class="col-2 btn btn-warning" @click="goBack">
          <span class="material-icons">cancel</span>Cancel
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import slugify from "slugify";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "NewPicture",
  data() {
    return {
      picSlug: this.$route.params.slug,
      error: "",
      file: null,
      picture: {
        title: "New Picture",
        description: "A new picture",
        gallery: "",
        carousel: false,
        price: 0,
        height: 0,
        width: 0,
        material: "",
        full: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      getGalleryList: "galleryList",
      getPictureList: "pictureList",
    }),
    galleryList() {
      return this.getGalleryList.map((g) => {
        return g.slug;
      });
    },
  },
  methods: {
    ...mapActions(["createPicture"]),
    getFile(event) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", (e) => {
        const maxWidth = 1280
        const maxHeight = 1024
        // Create a new image
        const img = new Image();

        // Once the image is ready...
        img.onload = () => {
          // Create a new canvas
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Determine the new image dimensions based on maxWidth and maxHeight
          let width = img.width;
          let height = img.height;
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }
          canvas.width = width;
          canvas.height = height;

          // Draw the image to the canvas with the new sizes
          ctx.drawImage(img, 0, 0, width, height);
          // Build and return the resized image as an image file
          canvas.toBlob(
            (blob) => {
          console.log(e.name);
              this.picture.file = (new File([blob], e.name));
            },
            "image/jpeg",
            1
          );
        };

        // Begin to load the image -- first line triggers the img.onload() above, don't 'optimise' the sequence
        img.src = e.target.result;
        this.picture.full = img.src;
      });

      fileReader.readAsDataURL(event.target.files[0]);
      // this.picture.file = event.target.files[0];
    },
    goBack() {
      this.$router.go(-1);
    },
    savePicture(picture) {
      this.error = "";
      picture.slug = slugify(picture.title, {
        replacement: "-",
        remove: /[/$*_+~.,()'"!\-:@]/g,
        lower: true,
      });
      if (picture.slug == "new-picture") {
        this.error = "picture can't be called " + '"' + picture.title + '"';
      }
      if (
        this.picSlug != picture.slug &&
        this.getPictureList.filter((pic) => {
          return pic.slug == picture.slug;
        }).length === 1
      ) {
        this.error =
          "there's already a picture called " + '"' + picture.title + '"';
      }
      if (!picture.file) {
        this.error = "No picture to add";
      }
      if (!this.error) {
        this.createPicture(picture);
        this.goBack();
      } else {
        alert(this.error);
      }
    },
  },
};
</script>
